<template>
  <a-card :bordered="false">
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="hanldleAdd()">新建</a-button>
      <a-button
        type="primary"
        icon="minus"
        @click="handleDelete(selectedRowKeys)"
        :disabled="!hasSelected()"
        :loading="loading"
      >
        删除
      </a-button>
      <a-button type="primary" icon="redo" @click="getDataList()">刷新</a-button>
    </div>

    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="10">
          <a-col :md="4" :sm="24">
            <a-form-item label="查询类别">
              <a-select allowClear v-model="queryParam.condition">
                <a-select-option key="ProductName">产品名</a-select-option>
                <a-select-option key="OrderSN">订单编号</a-select-option>
                <a-select-option key="OrderName">订单名称</a-select-option>
                <!-- <a-select-option key="ChargerId">主管Id</a-select-option>
                <a-select-option key="InspectorId">检验Id</a-select-option> 
                <a-select-option key="Remarks">备注</a-select-option>-->
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item>
              <a-input v-model="queryParam.keyword" placeholder="关键字" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-button
              type="primary"
              @click="
                () => {
                  this.pagination.current = 1
                  this.getDataList()
                }
              "
            >
              查询
            </a-button>
            <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <a-table
      ref="table"
      :columns="columns"
      :rowKey="row => row.Id"
      :dataSource="data"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :bordered="true"
      :scroll="{ x: 1500 }"
      size="small"
    >
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record.Id)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleTaskLog(record.Id)">任务日志</a>
          <a-divider type="vertical" />
          <a @click="handleDelete([record.Id])">删除</a>
        </template>
      </span>
      <span slot="OrderDate" slot-scope="text">
        {{ text | dateConvert }}
      </span>
      <span slot="ProcessDate" slot-scope="text">
        {{ text | shortHandTime }}
      </span>
      <span slot="CompleteDate" slot-scope="text">
        {{ text | dateConvert }}
      </span>
    </a-table>

    <edit-form ref="editForm" :parentObj="this"></edit-form>
    <task-log ref="taskLog" :parentObj="this"></task-log>
  </a-card>
</template>

<script>
import EditForm from './EditForm'
import TaskLog from '../Process/TaskLog'

const columns = [
  { title: '产品名', dataIndex: 'ProductName', width: 150, ellipsis: true },
  { title: '订单编号', dataIndex: 'OrderSN', width: 150, ellipsis: true },
  { title: '订单名称', dataIndex: 'OrderName', width: 150, ellipsis: true },
  {
    title: '下单日期',
    dataIndex: 'OrderDate',
    width: 100,
    ellipsis: true,
    scopedSlots: { customRender: 'OrderDate' },
  },
  {
    title: '计划完成日期',
    dataIndex: 'RequireDate',
    width: 100,
    ellipsis: true,
    scopedSlots: { customRender: 'RequireDate' },
  },
  {
    title: '生产日期',
    dataIndex: 'ProcessDate',
    width: 130,
    ellipsis: true,
    scopedSlots: { customRender: 'ProcessDate' },
  },
  {
    title: '完工日期',
    dataIndex: 'CompleteDate',
    width: 100,
    ellipsis: true,
    scopedSlots: { customRender: 'CompleteDate' },
  },
  { title: '订单状态', dataIndex: 'OrderStatusName', width: 100, ellipsis: true },
  { title: '主管', dataIndex: 'ChargerName', width: 120, ellipsis: true },
  { title: '检验', dataIndex: 'InspectorName', width: 120, ellipsis: true },
  { title: '备注', dataIndex: 'Remarks', width: 150, ellipsis: true },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    width: 120,
    ellipsis: true,
    fixed: 'right',
  },
]

export default {
  components: {
    EditForm,
    TaskLog,
  },
  mounted() {
    this.getDataList()
  },
  data() {
    return {
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showTotal: (total, range) => `总数:${total} 当前:${range[0]}-${range[1]}`,
      },
      filters: {},
      sorter: { field: 'CreateTime', order: 'desc' },
      loading: false,
      columns,
      queryParam: { condition: 'ProductName' },
      selectedRowKeys: [],
    }
  },
  methods: {
    handleTableChange(pagination, filters) {
      this.pagination = { ...pagination }
      this.filters = { ...filters }
      // this.sorter = { ...sorter }
      this.getDataList()
    },
    getDataList() {
      this.selectedRowKeys = []

      this.loading = true
      this.$http
        .post('/Main/Order/GetDataList', {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || 'CreateTime',
          SortType: this.sorter.order || 'desc',
          Search: this.queryParam,
          ...this.filters,
        })
        .then(resJson => {
          this.loading = false
          this.data = resJson.Data
          const pagination = { ...this.pagination }
          pagination.total = resJson.Total
          if (resJson.Data.length == 0 && resJson.Total > 0 && pagination.current > 1) {
            pagination.current = Math.ceil(resJson.Total / pagination.pageSize)
            this.pagination = pagination
            this.getDataList()
          }
          this.pagination = pagination
        })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
    hanldleAdd() {
      this.$refs.editForm.openForm()
    },
    handleEdit(id) {
      this.$refs.editForm.openForm(id)
    },
    handleTaskLog(id) {
      this.$refs.taskLog.openForm(id)
    },
    handleDelete(ids) {
      var thisObj = this
      this.$confirm({
        title: '确认删除吗?',
        onOk() {
          return new Promise(resolve => {
            thisObj.$http.post('/Main/Order/DeleteData', ids).then(resJson => {
              resolve()

              if (resJson.Success) {
                thisObj.$message.success('操作成功!')

                thisObj.getDataList()
              } else {
                thisObj.$message.error(resJson.Msg)
              }
            })
          })
        },
      })
    },
  },
}
</script>
<style>
.ant-table td {
  white-space: nowrap;
}
</style>
