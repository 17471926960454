<template>
  <a-select
    v-bind="$attrs"
    v-on="$listeners"
    placeholder="请选择产品"
    style="width: 100%"
    show-search
    :filter-option="filterOption"
    :not-found-content="fetching ? undefined : null"
    @change="handleChange"
  >
    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
    <a-select-option v-for="d in data" :key="d.Id" :value="d.Id">
      {{ d.ProductName }}
    </a-select-option>
  </a-select>
</template>
<script>
export default {
  data() {
    console.log(typeof this.getProducts)
    return {
      data: [],
      fetching: false,
    }
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleChange(ev, _vnode) {
      this.$emit('input', ev)
    },
    getProducts() {
      this.data = []
      this.fetching = true
      this.$ajax.post(`/Main/Product/GetAllSimpleList`, {}).then(body => {
        this.data = body
        this.fetching = false
      })
    },
  },
  mounted() {
    this.getProducts()
  },
}
</script>
