<template>
  <a-modal
    :title="title"
    width="40%"
    :visible="visible"
    :confirmLoading="loading"
    @ok="handleSubmit"
    @cancel="
      () => {
        this.visible = false
      }
    "
  >
    <a-spin :spinning="loading">
      <a-form-model ref="form" :model="entity" :rules="rules" v-bind="layout">
        <a-form-model-item label="产品" prop="ProductId">
          <ProductSelect v-model="entity.ProductId" />
        </a-form-model-item>
        <a-form-model-item label="订单编号" prop="OrderSN">
          <a-input v-model="entity.OrderSN" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item label="订单名称" prop="OrderName">
          <a-input v-model="entity.OrderName" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item label="下单日期" prop="OrderDate">
          <i-date-picker v-model="entity.OrderDate" />
        </a-form-model-item>
        <a-form-model-item label="计划完成日期" prop="RequireDate">
          <i-date-picker v-model="entity.RequireDate" />
        </a-form-model-item>
        <a-form-model-item label="主管" prop="ChargerId">
          <UserSelect v-model="entity.ChargerId" placeholder="请选择主管" />
        </a-form-model-item>
        <a-form-model-item label="检验人" prop="InspectorIds">
          <UserSelect v-model="entity.InspectorIds" placeholder="请选择检验人" allowClear mode="multiple" />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="Remarks">
          <a-input v-model="entity.Remarks" autocomplete="off" type="textarea" />
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </a-modal>
</template>

<script>
import ProductSelect from '@/components/SearchSelect/Product'
import UserSelect from '@/components/SearchSelect/User'
export default {
  name: 'OrderDeitForm',
  components: { ProductSelect, UserSelect },
  props: {
    parentObj: Object,
  },
  data() {
    return {
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
      },
      visible: false,
      loading: false,
      entity: {},
      rules: {
        ProductId: [{ required: true, message: '请选择产品', trigger: 'change' }],
        OrderSN: [{ required: true, message: '请输入订单编号', trigger: 'change' }],
        OrderName: [{ required: true, message: '请输入订单名称', trigger: 'change' }],
        OrderDate: [{ required: true, message: '请选择下单日期', trigger: 'change' }],
        RequireDate: [{ required: true, message: '请选择计划完成日期', trigger: 'change' }],
        ChargerId: [{ required: true, message: '请选择主管', trigger: 'change' }],
        InspectorIds: [{ required: true, message: '请选择检验', trigger: 'change' }],
      },
      title: '',
    }
  },
  methods: {
    init() {
      this.visible = true
      this.entity = {}
      this.$nextTick(() => {
        this.$refs['form'].clearValidate()
      })
    },
    openForm(id) {
      this.init()

      if (id) {
        this.loading = true
        this.$http.post('/Main/Order/GetTheData', { id: id }).then(resJson => {
          this.loading = false

          this.entity = resJson.Data
        })
      }
    },
    handleSubmit() {
      this.$refs['form'].validate(valid => {
        if (!valid) {
          return
        }
        this.loading = true
        this.$http
          .post('/Main/Order/SaveData', this.entity)
          .then(resJson => {
            this.loading = false

            if (resJson.Success) {
              this.$message.success('操作成功!')
              this.visible = false

              this.parentObj.getDataList()
            } else {
              this.$message.error(resJson.Msg)
            }
          })
          .catch(() => {
            this.loading = false
          })
      })
    },
  },
}
</script>
