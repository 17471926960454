<template>
  <a-modal
    :title="title"
    width="80%"
    :visible="visible"
    :confirmLoading="loading"
    @ok="
      () => {
        this.visible = false
      }
    "
    @cancel="
      () => {
        this.visible = false
      }
    "
  >
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24" v-if="!SearchKeys.orderId">
              <a-form-item label="订单名称">
                <a-input v-model="SearchKeys.orderName" placeholder="请输入订单编号或订单名称" />
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="操作者">
                <UserSelect v-model="SearchKeys.operators" placeholder="请选择操作者" allowClear mode="multiple" />
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-button
                type="primary"
                @click="
                  () => {
                    this.pagination.current = 1
                    this.getDataList()
                  }
                "
              >
                查询
              </a-button>
              <a-button style="margin-left: 8px" @click="() => (SearchKeys = {})">重置</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <a-table
        ref="table"
        :columns="columns"
        :rowKey="row => row.Id"
        :dataSource="data"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :bordered="true"
        size="small"
        style="margain"
      ></a-table>
    </a-card>
  </a-modal>
</template>

<script>
import UserSelect from '@/components/SearchSelect/User'
const columns = [
  { title: '订单名称', dataIndex: 'OrderName' },
  { title: '订单编号', dataIndex: 'OrderSN' },
  { title: '任务名称', dataIndex: 'ProcessName' },
  { title: '创建时间', dataIndex: 'CreateTime' },
  { title: '操作者', dataIndex: 'CreatorRealName' },
  { title: '内容', dataIndex: 'Message' },
]

export default {
  components: { UserSelect },
  mounted() {
    this.getDataList()
  },
  data() {
    return {
      data: [],
      title: '订单任务日志',
      pagination: {
        current: 1,
        pageSize: 10,
        showTotal: (total, range) => `总数:${total} 当前:${range[0]}-${range[1]}`,
      },
      filters: {},
      sorter: { field: 'Id', order: 'desc' },
      loading: false,
      columns,
      queryParam: {},
      SearchKeys: {},
      visible: false,
      selectedRowKeys: [],
    }
  },
  methods: {
    openForm(id, _title) {
      this.visible = true
      this.SearchKeys = {}
      this.SearchKeys.orderId = id
      this.pagination.current = 1
      this.getDataList()
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination = { ...pagination }
      this.filters = { ...filters }
      this.sorter = { ...sorter }
      this.getDataList()
    },
    getDataList() {
      this.queryParam.SearchKeys = JSON.stringify(this.SearchKeys)
      this.selectedRowKeys = []
      this.loading = true
      this.$http
        .post('/Main/Processtasklog/GetDataList', {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || 'Id',
          SortType: this.sorter.order,
          Search: this.queryParam,
          ...this.filters,
        })
        .then(resJson => {
          this.loading = false
          this.data = resJson.Data
          const pagination = { ...this.pagination }
          pagination.total = resJson.Total
          if (resJson.Data.length == 0 && resJson.Total > 0 && pagination.current > 1) {
            pagination.current = Math.ceil(resJson.Total / pagination.pageSize)
            this.pagination = pagination
            this.getDataList()
          }
          this.pagination = pagination
        })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
  },
}
</script>
<style scoped>
.ant-table-wrapper {
  margin-top: 10px;
}
</style>
